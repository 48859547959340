import React, { Component, FormEvent, RefObject } from "react";
import { PledgeFormProps } from "../../../../models/BannerModel";
import axiosClient from "../../../../config/AxiosRestClient";
import toast from "react-hot-toast";
import LoaderButton from "../../../../components/LoaderButton";
import pledgeFormImg from "../../../../assets/css/images/WhatsApp Image 2024-11-08 at 17.49.20.jpeg";
import "./PledgeForm.scss";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import ScrollAnimation from "react-animate-on-scroll";

interface PledgeFormBscState {
    processing: boolean;
    messageSent: boolean;
    formId: number | null;
    name: string;
    gender: string;
    pincode: string;
    state: string;
    district: string;
    email: string;
    mobile: string;
    dob: string;
    previewVisible: boolean;
    certificatePreviewUrl: string;
}

class PledgeForm extends Component<PledgeFormProps, PledgeFormBscState> {
    form: RefObject<HTMLFormElement>;

    constructor(props: PledgeFormProps) {
        super(props);
        this.state = {
            processing: false,
            messageSent: false,
            formId: null,
            name: "",
            gender: "",
            district: "",
            state: "",
            pincode: "",
            email: "",
            dob: "",
            mobile: "",
            previewVisible: false,
            certificatePreviewUrl: "",
        };
        this.form = React.createRef();
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        this.setState({ [name]: value } as unknown as PledgeFormBscState);
    };

    getPayload = (): any => {
        const { email, name, gender, mobile, district, state, pincode, dob } = this.state;
        return { email, name, gender, mobile, district, state, pincode, dob };
    };

    submitPledgeForm = (event: FormEvent) => {
        event.preventDefault();
        this.setState({ processing: true });

        axiosClient
            .post(`/listingfy-service/pledge`, this.getPayload())
            .then((res) => {
                if (res.status === 200) {
                    const previewUrl = res.data.previewUrl; // API provides certificate URL

                    this.setState({
                        processing: false,
                        messageSent: true,
                        formId: res.data.id,
                        previewVisible: true,
                        certificatePreviewUrl: previewUrl,
                    });

                    toast.success("Form submitted successfully");
                } else {
                    this.setState({ processing: false });
                    toast.error("Unknown error submitting form");
                }
            })
            .catch((error) => {
                console.error("Pledge Form Submission Error:", error);
                this.setState({ processing: false });
                toast.error("Form already submitted by user");
            });
    };

    downloadCertificate = () => {
        const { certificatePreviewUrl } = this.state;
        if (!certificatePreviewUrl) {
            toast.error("Certificate URL not available for download");
            return;
        }

        const link = document.createElement("a");
        link.href = certificatePreviewUrl;
        link.setAttribute("download", "PledgeFormCertificate.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    renderPreviewScreen = () => {
        const { certificatePreviewUrl } = this.state;

        return (
            <div className="text-center">
                <h2 className="text-success">Congratulations!</h2>
                <p>Your pledge form has been successfully submitted. Here is your certificate:</p>
                {certificatePreviewUrl ? (
                    <img
                        src={certificatePreviewUrl}
                        alt="Certificate Preview"
                        className="img-fluid rounded shadow my-4"
                        style={{ maxWidth: "80%" }}
                        onError={(e) => {
                            e.currentTarget.src = "/path/to/default-image.jpg"; // Fallback image
                            e.currentTarget.alt = "Preview not available";
                        }}
                    />
                ) : (
                    <p>Loading preview...</p>
                )}
                <div
                    onClick={this.downloadCertificate}
                    className="d-flex justify-content-center mt-3 button button_theme py-2 text-white overflow-hidden position-relative"
                >
                    <i className="bi bi-download"></i>
                    <LoaderButton loading={this.state.processing} label={"Download Certificate"} cssName={"btn button_theme"} />
                </div>
            </div>
        );
    };


    renderForm() {
        return (
            <div>
                <div className="row justify-content-center mb-3">
                    <ScrollAnimation animateIn='fadeInUp'>
                        <HeadingTitles heading={this.props.data['title']} classesObject={{
                            headingClass: 'heading-style mb-0',
                            descriptionClass: 'sub-title text-muted'
                        }} layout="layout_1" headingTag="h2" alignment="center"/>
                    </ScrollAnimation>
                </div>
                <div className="pledge-form text-center">
                    <form id="contact-form" role="form" onSubmit={this.submitPledgeForm}>
                        <div className="row">
                            <div className="user__details">
                                <div className="input__box">
                                    <span className="details">Name | नाम</span>
                                    <input className={'input'} type="text"
                                           placeholder="E.g: John Smith"
                                           name={"name"}
                                           onChange={event => this.handleChange(event)}/>
                                </div>
                                <div className="input__box">
                                    <span className="details">Email | ईमेल</span>
                                    <input className={'input'} type="email"
                                           placeholder="email**11@hotmail.com"
                                           name={"email"}
                                           onChange={event => this.handleChange(event)}/>
                                </div>
                                <div className="input__box">
                                    <span className="details">Date of Birth | जन्मतिथि</span>
                                    <input className={'input'} type="date"
                                           placeholder="02/06/2011"
                                           name={"dob"}
                                           onChange={event => this.handleChange(event)}/>
                                </div>
                                <div className="input__box">
                                    <span className="details">Gender | लिंग</span>
                                    <div className={'d-flex'}>
                                        <div className={'d-flex mt-3 mb-3'}>
                                            <input type="radio" id={'gender'} value='MALE'
                                                   name="gender"
                                                   className={'me-4'}
                                                   onChange={event => this.handleChange(event)}/>
                                            <label id={'gender'}>Male</label>
                                        </div>
                                        <div className={'d-flex ms-4 mt-3 mb-3'}>
                                            <input type="radio" id={'gender'} value='FEMALE'
                                                   name="gender"
                                                   className={'me-4'}
                                                   onChange={event => this.handleChange(event)}/>
                                            <label id={'gender'}>Female</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="input__box">
                                    <span className="details">Phone Number | मोबाइल</span>
                                    <input type="tel" className={'input'}
                                           placeholder="98*** ***18"
                                           name={"mobile"}
                                           onChange={event => this.handleChange(event)}/>
                                </div>
                                <div className="input__box">
                                    <span className="details">State / राज्य</span>
                                    <select name={"state"} className={'input'}
                                            onChange={event => this.handleChange(event)}>
                                        <option value="">Select State / राज्य चुनें</option>
                                        <option value="Andhra Pradesh">Andhra Pradesh / आंध्र
                                            प्रदेश
                                        </option>
                                        <option value="Arunachal Pradesh">Arunachal Pradesh /
                                            अरुणाचल
                                            प्रदेश
                                        </option>
                                        <option value="Assam">Assam / असम</option>
                                        <option value="Bihar">Bihar / बिहार</option>
                                        <option value="Chhattisgarh">Chhattisgarh / छत्तीसगढ़
                                        </option>
                                        <option value="Goa">Goa / गोवा</option>
                                        <option value="Gujarat">Gujarat / गुजरात</option>
                                        <option value="Haryana">Haryana / हरियाणा</option>
                                        <option value="Himachal Pradesh">Himachal Pradesh /
                                            हिमाचल
                                            प्रदेश
                                        </option>
                                        <option value="Jharkhand">Jharkhand / झारखंड</option>
                                        <option value="Karnataka">Karnataka / कर्नाटक</option>
                                        <option value="Kerala">Kerala / केरल</option>
                                        <option value="Madhya Pradesh">Madhya Pradesh / मध्य
                                            प्रदेश
                                        </option>
                                        <option value="Maharashtra">Maharashtra / महाराष्ट्र
                                        </option>
                                        <option value="Manipur">Manipur / मणिपुर</option>
                                        <option value="Meghalaya">Meghalaya / मेघालय</option>
                                        <option value="Mizoram">Mizoram / मिजोरम</option>
                                        <option value="Nagaland">Nagaland / नागालैंड</option>
                                        <option value="Odisha">Odisha / ओडिशा</option>
                                        <option value="Punjab">Punjab / पंजाब</option>
                                        <option value="Rajasthan">Rajasthan / राजस्थान</option>
                                        <option value="Sikkim">Sikkim / सिक्किम</option>
                                        <option value="Tamil Nadu">Tamil Nadu / तमिलनाडु
                                        </option>
                                        <option value="Telangana">Telangana / तेलंगाना</option>
                                        <option value="Tripura">Tripura / त्रिपुरा</option>
                                        <option value="Uttar Pradesh">Uttar Pradesh / उत्तर
                                            प्रदेश
                                        </option>
                                        <option value="Uttarakhand">Uttarakhand / उत्तराखंड
                                        </option>
                                        <option value="West Bengal">West Bengal / पश्चिम बंगाल
                                        </option>
                                        <option value="Andaman and Nicobar Islands">Andaman and
                                            Nicobar
                                            Islands / अंडमान और निकोबार द्वीप
                                        </option>
                                        <option value="Chandigarh">Chandigarh / चंडीगढ़</option>
                                        <option
                                            value="Dadra and Nagar Haveli and Daman and Diu">Dadra
                                            and Nagar Haveli and Daman and Diu / दादरा और नगर
                                            हवेली और
                                            दमन और दीव
                                        </option>
                                        <option value="Delhi">Delhi / दिल्ली</option>
                                        <option value="Lakshadweep">Lakshadweep / लक्षद्वीप
                                        </option>
                                        <option value="Puducherry">Puducherry / पुदुचेरी
                                        </option>
                                    </select>
                                </div>

                                <div className="input__box">
                                                    <span
                                                        className="details">City/Town/Village | शहर / कस्बा / गांव</span>
                                    <input type="text" placeholder="E.g: U.P" name={"district"}
                                           className={'input'}
                                           onChange={event => this.handleChange(event)}/>
                                </div>
                                <div className="input__box">
                                    <span className="details">Pincode | पिन कोड</span>
                                    <input type="text" placeholder="E.g: 282010"
                                           name={"pincode"}
                                           className={'input'}
                                           onChange={event => this.handleChange(event)}/>
                                </div>
                            </div>
                        </div>
                        <div
                            className="d-flex justify-content-center mt-3 button button_theme py-2 text-white overflow-hidden position-relative">
                            <LoaderButton loading={this.state.processing} label={'Take Pledge'}
                                          cssName={"btn button_theme"}/>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    render() {
        const {previewVisible} = this.state;

        return (
            <section
                id={this.props.htmlId}
                className={`${this.props.themeClass} pledgeForm ptb_80 testingFontFamily`}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-center">
                                <img src={pledgeFormImg} className="w-75 rounded"/>
                            </div>
                            <p className="text-white mt-3" style={{textAlign: "justify"}}>
                                At Pushpa Seva Foundation, we are passionate about making a difference in the
                                lives of those affected by cancer. We strive to empower individuals with the
                                knowledge and resources they need to make informed decisions about their health
                                and well-being. With our dedicated team of volunteers and partners, we are
                                committed to making a positive impact in the fight against cancer.We also provide
                                support and
                                counseling services to those affected by cancer, including patients, survivors, and
                                their families.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <div className="bg-white rounded pt-3 pb-4 px-4">
                                {previewVisible ? this.renderPreviewScreen() : this.renderForm()}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default PledgeForm;
