import React, {useEffect, useState} from "react";
import "../gallery/GalleryLayout1.scss"
import "./MediaSelector.scss";
import Carousel from "react-bootstrap/Carousel";
import LazyLoad from "react-lazyload";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import {UniversalReadMoreButton} from "../../../../components/UniversalReadMoreButton/UniversalReadMoreButton";
import {useLocation} from "react-router";

export const enum MediaSelectedType {
    IMAGE = "image",
    VIDEO = "youtube",
    IMAGE_SLIDER = "slides",
    INFO_IMAGE = "infoImage",
    MARKUP = "markdown",
    EMPTY = "empty",
    CLICK_TO_ACTION = "clickToAction",
}

export const MediaSelector = (props: { data: any,htmlId?:any }) => {
    const location = useLocation();
    useEffect(() => {
        setSizeCLass(props?.data?.size);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        console.log('25',props.data)
    }, [props]);
    const [state, setState] = useState({
        sizeClass: props?.data?.size,
    });

    const setSizeCLass = (size: string) => {
        if (size === 'XS') {
            setState(() => ({sizeClass: 25}));
        } else if (size === 'S') {
            setState(() => ({sizeClass: 50}));
        } else if (size === 'M') {
            setState(() => ({sizeClass: 75}));
        } else if (size === 'L') {
            setState(() => ({sizeClass: 100}));
        }
    }
    const MediaType = () => {
        switch (props.data?.type) {
            case MediaSelectedType.VIDEO:
                let youtubeId = props?.data?.content;
                youtubeId = youtubeId.substring('https://www.youtube.com/watch?v='.length);
                let muted: string = "";
                if (props?.data?.mute) {
                    muted = "&mute=1";
                } else {
                    muted = "";
                }
                return <div className="iframe-container">
                    <iframe
                        src={`https://www.youtube.com/embed/${youtubeId.trim()}?autoplay=${props?.data?.autoplay ? "1" : "0"}${muted.trim()}`}
                        title="YouTube video player" frameBorder="0"
                        allow={`accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture`}
                        allowFullScreen></iframe>
                </div>

            case MediaSelectedType.IMAGE:
                return <LazyLoad offset={100} className="d-flex justify-content-center h-100"><img
                    style={{width: `${state.sizeClass ? state.sizeClass : 75}%`}}
                    src={props.data?.content?.url} loading='lazy' alt={""}/></LazyLoad>
            case MediaSelectedType.IMAGE_SLIDER:
                return <Carousel indicators={false} controls={false}>
                    {props?.data.content.map((image: any, i: any) =>
                        <Carousel.Item className="flex-img" interval={props.data?.delay}>
                            <LazyLoad offset={100} className="d-flex justify-content-center h-100">
                                <img className="img-fluid d-block"
                                     style={{height: `${state.sizeClass ? state.sizeClass : 50}vh`}}
                                     src={props.data?.content[i]?.url} alt={i + 1} loading='lazy'/>
                            </LazyLoad>
                        </Carousel.Item>
                    )}
                </Carousel>
            case MediaSelectedType.MARKUP:
                return <div>
                    <div className="section-header d-flex align-items-center">
                        {props.data?.showTitle ? <div className="float-start">
                            <HeadingTitles heading={props.data['title']} classesObject={{
                                headingClass: 'heading-style',
                                descriptionClass: 'mini-title'
                            }} headingTag="h2" description={props.data['subtitle']} alignment="start"
                                           layout="layout_2"/>
                        </div> : <div></div>}
                    </div>
                    <p dangerouslySetInnerHTML={{__html: props?.data?.content?.html}} className="title_description"
                       style={{margin: "0px", textAlign: "justify"}}/>
                </div>
            case MediaSelectedType.EMPTY:


                return <div className="flexImage-img img-fluid img-responsive"></div>
            case MediaSelectedType.INFO_IMAGE:
                return <div>
                    <div className="position-relative d-block zoom-effect-1" style={{height: '250px'}}>
                        <LazyLoad offset={100}>
                            <img
                                style={{width: `${state.sizeClass ? state.sizeClass : 75}%`}}
                                src={props.data?.content?.url} loading='lazy' alt={""}/>
                        </LazyLoad>
                    </div>
                    <div className="mt-4 head_wrap">
                        <h3 className="heading-style">{props.data?.title}</h3>
                        {props.data?.title ?
                            <span className="label-line"></span> : ''}
                        {
                            props.data?.desc?.html ?
                                <div dangerouslySetInnerHTML={{__html: props.data?.desc?.html || ''}}
                                     className={'mt-1 mx-md-3 product__description mini-title'}></div>
                                :

                                <p className="sub-title">{props.data?.description}</p>

                        }

                    </div>
                </div>
            case MediaSelectedType.CLICK_TO_ACTION:
                return <div>
                    <div
                        className={`mb-4 ${props.data?.content?.url ? "card shadow-sm" : ""}`}
                        style={{
                            width: `${state.sizeClass || 75}%`,
                            margin: "0 auto",
                        }}
                    >
                        {props.data?.content?.url && (
                            <LazyLoad offset={100} className="m-3 rounded position-relative d-block zoom-effect-1">
                                <img
                                    style={{width: "100%", maxHeight: "280px"}}
                                    src={props.data.content.url}
                                    loading="lazy"
                                    alt={props.data?.title || "Image"}
                                    className="card-img-top object-fit-fill"
                                />
                            </LazyLoad>
                        )}
                        <div className={`${props.data?.content?.url ? "card-body" : ""}`}>
                            <h3 className="heading-style mb-4">{props.data?.title}</h3>
                            <div
                                dangerouslySetInnerHTML={{__html: props.data?.desc?.markup || ""}}
                                className="mt-1 product__description"
                            ></div>
                            {props.data?.description && (
                                <p className="sub-title mt-3">{props.data.description}</p>
                            )}
                            <div
                                className="d-flex justify-content-center mt-3 button button_theme text-white overflow-hidden position-relative">
                                <UniversalReadMoreButton
                                    payload={props?.data?.action}
                                    pathName={props?.data?.location?.pathname || ""}
                                    siteId={props?.htmlId}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            default:
                return <div></div>
        }
    }
    return (<div className="video_wrapper w-100 h-100 d-flex justify-content-center align-items-center">
        {MediaType()}
    </div>)
}
